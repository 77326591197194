footer {
  position: relative;
  z-index: 21;
  background-color: #E9ABE1;
  padding: calc(50 / 1440 * 100vw) 0 calc(70 / 1440 * 100vw);

  @media (max-width: $screen-xxl) {
    padding: 50px 12px;
  }

  @media (max-width: $screen-lg) {
    padding: 40px 12px;
  }

  @media (max-width: $screen-sm) {
    padding: 30px 0;
  }

  .container {
    @media (max-width: $screen-sm) {
      padding: 0 20px;
    }
  }

  .before-wrap {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: calc(100% - 1px);
    width: 100vw;

    @media (max-width: $screen-sm) {
      overflow: hidden;
    }

    img {
      width: 100%;

      @media (max-width: $screen-sm) {
        width: calc(110vw);
        margin-left: -5vw;
      }
    }
  }

  .col-12 {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-md) {
      flex-direction: column-reverse;
    }
  }

  .socials-wrap {
    display: flex;
    margin-bottom: calc(40 / 1440 * 100vw);

    @media (max-width: $screen-xxl) {
      margin-bottom: 40px;
    }

    @media (max-width: $screen-md) {
      justify-content: center;
      margin-bottom: 24px;
    }

    a {
      display: block;
      width: calc(40 / 1440 * 100vw);
      margin-right: calc(10 / 1440 * 100vw);
      text-decoration: none;

      @media (max-width: $screen-xxl) {
        width: 40px;
        margin-right: 10px;
      }

      @media (max-width: $screen-md) {
        margin: 0 6px;
      }

      img {
        width: 100%;
      }
    }
  }

  .copyright-wrap {

    p {
      font: 400 calc(16 / 1440 * 100vw)/1.2 $main-font;
      color: $white-color;
      margin: 0;

      @media (max-width: $screen-xxl) {
        font-size: 16px;
      }

      @media (max-width: $screen-md) {
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .form-wrap {
    margin: 0 0 calc(30 / 1440 * 100vw);

    @media (max-width: $screen-xxl) {
      margin: 0 0 30px;
    }

    @media (max-width: $screen-md) {
      margin: 0 0 24px;
    }

    p {
      font: 400 calc(16 / 1440 * 100vw)/1.2 $main-font;
      color: $white-color;
      margin: 0 0 calc(12 / 1440 * 100vw);

      @media (max-width: $screen-xxl) {
        font-size: 16px;
        margin: 0 0 12px;
      }

      @media (max-width: $screen-md) {
        text-align: center;
      }
    }

    .form-group {
      display: flex;
      align-items: center;

      input {
        font: 400 calc(12 / 1440 * 100vw)/1.2 $main-font;
        color: $white-color;
        width: calc(300 / 1440 * 100vw);
        height: calc(40 / 1440 * 100vw);
        margin-right: calc(8 / 1440 * 100vw);
        border-radius: calc(4 / 1440 * 100vw);
        border: calc(1 / 1440 * 100vw) solid $white-color;
        background-color: transparent;
        box-shadow: none;
        outline: none;

        @media (max-width: $screen-xxl) {
          font-size: 12px;
          width: 300px;
          height: 40px;
          margin-right: 8px;
          border-radius: 4px;
          border: 1px solid $white-color;
        }

        @media (max-width: $screen-md) {
          flex: 1;
        }

        &::placeholder {
          font: 400 calc(12 / 1440 * 100vw)/1.2 $main-font;
          color: $white-color;

          @media (max-width: $screen-xxl) {
            font-size: 12px;
          }
        }
      }

      button {
        color: $white-color;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: calc(40 / 1440 * 100vw);
        height: calc(40 / 1440 * 100vw);
        border: 0;
        border-radius: calc(4 / 1440 * 100vw);
        background-color: $white-color;

        @media (max-width: $screen-xxl) {
          height: 40px;
          min-width: 40px;
          border-radius: 4px;
        }

        &:hover {
          opacity: .9;
        }

        .text {
          font: 600 calc(12 / 1440 * 100vw)/1.2 $main-font;
          color: #E9ABE1;

          @media (max-width: $screen-xxl) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .links-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $screen-sm) {
      width: 100%;
    }

    @media (max-width: $screen-md) {
      margin: 0 0 24px;
    }

    a {
      font-size: calc(16 / 1440 * 100vw);
      font-family: $main-font;
      font-weight: 400;
      color: $white-color;
      text-transform: uppercase;
      text-decoration: none;
      margin: 0;

      @media (max-width: $screen-xxl) {
        font-size: 16px;
      }

      @media (max-width: $screen-sm) {
        margin: 0;
      }
    }
  }
}
