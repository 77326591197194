.btn-main {
  font: 600 16px/1.2 $main-font;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  position: relative;
  max-width: max-content;

  &:hover {

    .icon {
      transform: translateX(5px);
    }
  }

  .text {
    margin-right: 24px;
  }

  &.btn-gradient {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    padding: 24px;
    border-radius: 40px;

    .text {
      position: relative;
      z-index: 2;
      background: linear-gradient(268.97deg, #29CCB9 -57.65%, #0091FF 22.67%, #FF66B7 106.26%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .icon {
      position: relative;
      z-index: 2;
    }

    .bg {
      position: absolute;
      top: -0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 40px;
      background-color: #E5FAFE;
    }

    .gradient {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      z-index: 0;
      border-radius: 40px;
      background: linear-gradient(300.61deg, #32FDEE 0%, #2F9BF2 37.5%, #8984D9 68.75%, #FA68B9 100%);
    }
  }

  &.btn-white {
    padding: 24px;
    border-radius: 40px;
    border: 1px solid $white-color;

    .text {
      color: $white-color;
    }
  }

  &.disabled {
    opacity: .6;
    pointer-events: none;
  }
}
