@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;&display=swap');

@font-face {
  font-family: 'HeadlinerNo.45';
  src: url('../../fonts/HeadlinerNo.45 DEMO.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'NBArchitektStdNeue';
  src: url('../../fonts/NBArchitektStdNeue-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NBArchitektStdNeue';
  src: url('../../fonts/NBArchitektStdNeue-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NBArchitektStdNeue';
  src: url('../../fonts/NBArchitektStdNeue-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

// Fonts
$main-font: 'NBArchitektStdNeue', sans-serif;
$headline-font: 'HeadlinerNo.45', sans-serif;
$text-font: 'Roboto', sans-serif;

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
