// Variables
@import "../vars/breakpoints";
@import "../vars/colors";
@import "../vars/transitions";
@import "../vars/typography";
@import "../vars/buttons";

:root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html {
  font-size: 14.4px;
}

* {
  transition: $fast-transition;
}

.container {

  @media (min-width: $screen-lg) {
    max-width: 1160px !important;
  }

  @media (min-width: $screen-xxl) {
    max-width: calc(1320 / 1440 * 100vw) !important;
    padding: 0;
  }

  @media (max-width: $screen-xl) {
    padding: 0 24px;
  }

  @media (max-width: $screen-lg) {
    max-width: 100%;
  }
}

[class^="col-"] {
  padding: 0 8px;
}

main {
  min-width: 1440px;
  overflow: hidden;

  @media (max-width: $screen-xxl) {
    min-width: 100vw;
    max-width: 100vw;
  }
}

.display-none {
  display: none !important;
}

.mobile-only {
  display: none !important;

  @media (max-width: $screen-lg) {
    display: flex !important;
  }
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: pan-x pan-y;
  background-color: #010101 !important;

  &::-webkit-scrollbar {
    display: none;
  }

  &.overflow-hidden {
    overflow: hidden;

    @media (max-width: $screen-lg) {
      overflow-y: auto !important;
    }
  }

  &.navbar-opened {
    overflow-y: hidden !important;
  }
}

@-webkit-keyframes scrollRotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scrollRotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.parallax {
  transition: none;
}

.loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: $black-color;

  img {
    animation: rotating 10s linear infinite;
    width: calc(120 / 1440 * 100vw);
    min-width: 80px;

    @media (max-width: $screen-md) {
      max-width: 150px;
    }
  }
}

@media screen and (max-width: $screen-lg) {
  [data-aos] {
    pointer-events: auto !important;
    transform: none !important;
  }

  html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 1 !important;
  }

  html:not(.no-js) [data-aos=fade-up] {
    transform: none !important;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
