.about-hero-section {
  position: relative;
  overflow: hidden;
  // background: linear-gradient(180deg, #163257 0%, #AAB0D7 100%);

  .swiper {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 10;

    @media (max-width: $screen-lg) {
      height: max-content;
      touch-action: auto !important;
    }

    .swiper-wrapper {
      @media (max-width: $screen-lg) {
        display: flex;
        flex-direction: column;
        height: max-content;
        transform: none;
      }
    }

    .swiper-slide {
      overflow: hidden;
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      text-align: left;
      padding: 0 calc(250 / 1440 * 100vw);

      @media (max-width: $screen-xxl) {
        padding: 0 60px;
      }

      @media (max-width: $screen-lg) {
        display: flex !important;
        height: max-content !important;
        text-align: center;
        padding: 0 20px;
      }

      &:first-child {
        @media (max-width: $screen-lg) {
          padding: 140px 20px 640px;
        }

        @media (max-width: $screen-sm) {
          padding: 105px 20px 430px;
        }
      }

      &:last-child {
        @media (max-width: $screen-lg) {
          padding: 0 20px 50px;
        }

        .second-slide-text-wrap {
          position: relative;
        }
      }

      .text-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: calc(115 / 1440 * 100vw);
        position: relative;
        z-index: 4;

        @media (max-width: $screen-xxl) {
          margin-top: 115px;
        }

        @media (max-width: $screen-lg) {
          margin: 0 auto;
          text-align: center;
        }

        &.second-slide-text-wrap {
          position: absolute;
          bottom: calc(80 / 1440 * 100vw);
          right: calc(70 / 1440 * 100vw);

          @media (max-width: $screen-xxl) {
            bottom: 80px;
            right: 70px;
          }

          @media (max-width: $screen-lg) {
            position: relative;
            bottom: 0;
            right: 0;
          }

          h2, p {
            text-shadow: 0 0 30px rgba($black-color, 0.8);

            @media (max-width: $screen-lg) {
              text-shadow: none;
            }
          }
        }

        h1 {
          font-size: calc(110 / 1440 * 100vw);
          font-family: $headline-font;
          line-height: 0.9;
          letter-spacing: calc(4.3 / 1440 * 100vw);
          max-width: calc(880 / 1440 * 100vw);
          color: $white-color;
          font-weight: 400;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: calc(40 / 1440 * 100vw);

          @media (max-width: $screen-xxl) {
            font-size: 90px;
            letter-spacing: 4.3px;
            margin-bottom: 40px;
          }

          @media (max-width: $screen-lg) {
            font-size: 108px;
            margin: 0 auto 32px;
            max-width: 700px;
          }

          @media (max-width: $screen-md) {
            font-size: 80px;
          }

          @media (max-width: $screen-sm) {
            font-size: 64px;
            max-width: 340px;
          }

          br {
            @media (max-width: $screen-xxl) {
              display: none;
            }
          }

          ~ a {

            .text {
              font-size: calc(18 / 1440 * 100vw);

              @media (max-width: $screen-xxl) {
                font-size: 18px;
              }

              @media (max-width: $screen-sm) {
                font-size: 14px;
              }
            }

            .bg svg {
              width: calc(270 / 1440 * 100vw);
              height: auto;

              @media (max-width: $screen-xxl) {
                width: 270px;
              }

              @media (max-width: $screen-sm) {
                width: 190px;
              }
            }
          }
        }

        h2 {
          font-size: calc(88 / 1440 * 100vw);
          font-family: $headline-font;
          line-height: 0.9;
          letter-spacing: calc(3.5 / 1440 * 100vw);
          color: $white-color;
          text-transform: uppercase;
          max-width: calc(700 / 1440 * 100vw);
          margin-bottom: calc(20 / 1440 * 100vw);

          @media (max-width: $screen-xxl) {
            font-size: 70px;
            letter-spacing: 3.5px;
            max-width: 700px;
            margin-bottom: 20px;
          }

          @media (max-width: $screen-lg) {
            font-size: 96px;
            margin: 0 auto 24px;
            max-width: 640px;
          }

          @media (max-width: $screen-sm) {
            font-size: 64px;
            margin: 0 auto 8px;
            max-width: 340px;
          }

          br {
            @media (max-width: $screen-lg) {
              display: none;
            }
          }

          ~ a {

            .text {
              font-size: calc(14 / 1440 * 100vw);

              @media (max-width: $screen-xxl) {
                font-size: 14px;
              }

              @media (max-width: $screen-lg) {
                font-size: 18px;
              }

              @media (max-width: $screen-sm) {
                font-size: 14px;
              }
            }

            .bg svg {
              width: calc(200 / 1440 * 100vw);
              height: auto;

              @media (max-width: $screen-xxl) {
                width: 200px;
              }

              @media (max-width: $screen-lg) {
                width: 270px;
              }

              @media (max-width: $screen-sm) {
                width: 190px;
              }
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          position: relative;

          @media (max-width: $screen-lg) {
            margin: 0 auto;
          }

          .text {
            color: $white-color;
            font-family: $main-font;
            font-weight: 700;
            position: absolute;
            margin: auto;
            text-transform: uppercase;
          }
        }

        p {
          font-size: calc(18 / 1440 * 100vw);
          font-family: $main-font;
          font-weight: 700;
          line-height: 1.2;
          color: $white-color;
          text-transform: uppercase;
          max-width: calc(430 / 1440 * 100vw);
          margin-bottom: calc(40 / 1440 * 100vw);

          @media (max-width: $screen-xxl) {
            font-size: 18px;
            max-width: 350px;
            margin-bottom: 30px;
          }

          @media (max-width: $screen-lg) {
            text-align: center;
            max-width: 460px;
            margin: 0 auto 40px;
          }

          @media (max-width: $screen-sm) {
            font-size: 14px;
            margin: 0 auto 32px;
            max-width: 340px;
            opacity: .7;
          }
        }
      }
    }
  }

  .bg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      opacity: 0;
      height: calc(200 / 1440 * 100vw);
      background: linear-gradient(0deg, #010101 0%, rgba(1, 1, 1, 0.00) 100%);
      transition: all 1s ease-in-out;

      @media (max-width: $screen-xxl) {
        height: 200px;
      }

      @media (max-width: $screen-lg) {
        opacity: 1;
      }
    }

    &.active-last {

      &::after {
        opacity: 1;
      }

      .character-wrap img {
        left: calc(380 / 1440 * 100vw);
        width: calc(560 / 1440 * 100vw);
        top: calc(30 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          left: 380px;
          width: 560px;
          top: 30px;
        }

        @media (max-width: $screen-xl) {
          right: -50px;
          bottom: -150px;
        }
      }

      .palms-wrap img {
        left: 0;
        width: 100vw;
        top: calc(-30 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          left: 0;
          top: -30px;
        }
      }

      .ground-wrap {
        top: 0;
        transform: scale(1);
      }

      .clouds-wrap img {
        opacity: 0;

        &.cloud-right-1 {
          transform: translateX(20vw);
        }

        &.cloud-right-2 {
          transform: translateX(20vw);
        }

        &.cloud-center-1 {
          transform: translateX(-20vw);
        }

        &.cloud-center-2 {
          transform: translateX(-20vw);
        }

        &.cloud-left-1 {
          transform: translateX(-20vw);
        }
      }

      .light-wrap img {
        opacity: 0;

        &.light-left {
          transform: translateX(-20vw);
        }

        &.light-right {
          transform: translateX(20vw);
        }
      }

      .particles-wrap img {
        opacity: 0;

        &.particles-left-2, &.particles-right-2 {
          opacity: 1;
        }
      }

      .bottom-wrap img {
        bottom: 0;
      }

      .bg-wrap img {
        margin-top: calc(-600 / 1440 * 100vw);
        height: calc(100% + 600 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          margin-top: -600px;
          height: calc(100% + 600px);
        }
      }

      ~ .scroll-wrap {
        opacity: 0;
      }
    }

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    * {
      transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -webkit-transition: all 1s ease-in-out;
    }

    .bg-wrap {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &::after {
        @media (max-width: $screen-lg) {
          content: '';
          display: block;
          width: 100%;
          height: 400px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          background: linear-gradient(0, #000 0%, rgba(0, 0, 0, 0.00) 100%);
        }
      }

      img {
        width: 100%;
        height: calc(100% + 200 / 1440 * 100vw);
        object-fit: cover;
        object-position: top;
        margin-top: calc(-200 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          margin-top: -200px;
          height: calc(100% + 200px);
        }

        @media (max-width: $screen-lg) {
          margin-top: 0;
          height: 100%;
        }
      }
    }

    .character-wrap {
      position: absolute;
      z-index: 4;

      @media (max-width: $screen-lg) {
        display: flex;
        justify-content: center;
      }
    }

    .character-wrap img {
      position: absolute;
      top: calc(320 / 1440 * 100vw);
      width: calc(1110 / 1440 * 100vw);
      left: calc(80 / 1440 * 100vw);
      z-index: 1;

      @media (max-width: $screen-xxl) {
        top: 320px;
        width: 1110px;
        left: 80px;
      }

      @media (max-width: $screen-xl) {
        right: -250px;
        bottom: -600px;
      }

      @media (max-width: $screen-lg) {
        left: auto;
        right: auto;
        bottom: 0;
        margin: 0 auto;
        width: 750px;
      }

      @media (max-width: $screen-sm) {
        width: 400px;
      }

      //bottom: calc(-580 / 1440 * 100vw);
      //width: calc(840 / 1440 * 100vw);
      //z-index: 1;
      //
      //@media (max-width: $screen-xxl) {
      //  right: -100px;
      //  bottom: -500px;
      //  width: 840px;
      //}
      //
      //@media (max-width: $screen-xl) {
      //  right: -250px;
      //  bottom: -600px;
      //}
      //
      //@media (max-width: $screen-lg) {
      //  left: auto;
      //  right: auto;
      //  bottom: 0;
      //  margin: 0 auto;
      //  width: 750px;
      //}
      //
      //@media (max-width: $screen-sm) {
      //  width: 400px;
      //}
    }

    .palms-wrap {
      position: absolute;
      z-index: 2;

      img {
        position: absolute;
        z-index: 0;
        top: calc(285 / 1440 * 100vw);
        left: calc(-370 / 1440 * 100vw);
        width: calc(2020 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          top: 285px;
          left: -370px;
          width: 2020px;
        }

        @media (max-width: $screen-lg) {
          width: 140%;
          left: -20%;
          top: 500px;
        }
      }
    }

    .ground-wrap {
      position: absolute;
      z-index: 3;
      top: calc(220 / 1440 * 100vw);
      transform: scale(2.4) translateX(-1%);
      transform-origin: top;

      @media (max-width: $screen-xxl) {
        top: 220px;
      }

      @media (max-width: $screen-lg) {
        top: 400px;
        transform: none;
        width: 100%;
      }

      @media (max-width: $screen-md) {
        top: 300px;
      }

      @media (max-width: $screen-sm) {
        top: 320px;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;

        @media (max-width: $screen-lg) {
          height: calc(100% - 400px);
        }

        @media (max-width: $screen-md) {
          height: calc(100% - 300px);
        }

        @media (max-width: $screen-sm) {
          height: calc(100% - 320px);
        }
      }
    }

    .clouds-wrap img {
      position: absolute;
      z-index: 2;

      &.cloud-right-1 {
        right: 0;
        top: calc(60 / 1440 * 100vw);
        width: calc(400 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          top: 60px;
          width: 400px;
        }
      }

      &.cloud-right-2 {
        bottom: 0;
        right: 0;
        width: calc(700 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          width: 700px;
        }
      }

      &.cloud-center-1 {
        bottom: calc(110 / 1440 * 100vw);
        right: calc(315 / 1440 * 100vw);
        width: calc(560 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          bottom: 110px;
          right: 315px;
          width: 560px;
        }
      }

      &.cloud-center-2 {
        top: calc(-100 / 1440 * 100vw);
        left: calc(-180 / 1440 * 100vw);
        width: 100vw;
        z-index: 0;

        @media (max-width: $screen-xxl) {
          top: -100px;
          left: -180px;
        }
      }

      &.cloud-left-1 {
        bottom: 0;
        left: 0;
        width: calc(400 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          width: 400px;
        }
      }
    }

    .light-wrap img {
      position: absolute;
      z-index: 3;

      &.light-left {
        width: calc(500 / 1440 * 100vw);
        bottom: 0;
        left: 0;

        @media (max-width: $screen-xxl) {
          width: 500px;
        }
      }

      &.light-right {
        width: calc(570 / 1440 * 100vw);
        bottom: 0;
        right: 0;

        @media (max-width: $screen-xxl) {
          width: 570px;
        }
      }
    }

    .particles-wrap {
      position: absolute;
      z-index: 5;

      img {
        position: absolute;

        &.particles-left-1 {
          width: calc(450 / 1440 * 100vw);
          bottom: 0;
          left: 0;

          @media (max-width: $screen-xxl) {
            width: 450px;
          }
        }

        &.particles-right-1 {
          width: calc(360 / 1440 * 100vw);
          top: calc(140 / 1440 * 100vw);
          right: 0;

          @media (max-width: $screen-xxl) {
            width: 360px;
            top: 140px;
          }
        }

        &.particles-left-2 {
          width: calc(600 / 1440 * 100vw);
          bottom: 0;
          left: 0;
          opacity: 0;

          @media (max-width: $screen-xxl) {
            width: 600px;
          }
        }

        &.particles-right-2 {
          width: calc(400 / 1440 * 100vw);
          top: 0;
          right: 0;
          opacity: 0;

          @media (max-width: $screen-xxl) {
            width: 400px;
          }
        }

        img {
          width: 100%;
        }
      }
    }

    .bottom-wrap img {
      position: absolute;
      z-index: 11;
      width: calc(100vw + 2px);
      height: auto;
      left: -1px;
      bottom: -20vw;
    }

    .clouds-wrap,
    .light-wrap,
    .particles-wrap,
    .bottom-wrap {
      @media (max-width: $screen-lg) {
        display: none;
      }
    }
  }

  .scroll-wrap {
    position: fixed;
    z-index: 18;
    bottom: calc(30 / 1440 * 100vw);
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: max-content;
    -webkit-animation: scrollRotating 15s linear infinite;
    -moz-animation: scrollRotating 15s linear infinite;
    -ms-animation: scrollRotating 15s linear infinite;
    -o-animation: scrollRotating 15s linear infinite;
    animation: scrollRotating 15s linear infinite;

    @media (max-width: $screen-xxl) {
      bottom: 30px;
      left: 35px;
    }

    @media (max-width: $screen-lg) {
      display: none;
    }

    img {
      width: calc(80 / 1440 * 100vw);

      @media (max-width: $screen-xxl) {
        width: 80px;
      }
    }
  }
}
