.characters-hero-section {
  background-color: #010101;
  min-height: (940 / 1440 * 100vw);
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: $screen-lg) {
    padding: 135px 20px 0;
  }

  @media (max-width: $screen-sm) {
    padding: 125px 0 96px;
  }

  .artifacts-wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @media (max-width: $screen-lg) {
      position: absolute;
    }

    @media (min-width: $screen-sm) {
      height: auto;
    }

    .artifacts-desktop {
      width: 100%;

      @media (max-width: $screen-lg) {
        display: none;
      }
    }

    .artifacts-tablet {
      width: 100%;
      height: 100%;

      @media (min-width: $screen-lg), (max-width: $screen-sm) {
        display: none;
      }
    }

    .artifacts-mobile {
      width: 100%;
      height: 100%;

      @media (min-width: $screen-sm) {
        display: none;
      }
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .video-wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;

    @media (max-width: $screen-lg) {
      position: absolute;
    }

    .video-desktop {
      @media (max-width: $screen-lg) {
        display: none;
      }
    }

    .video-tablet {
      @media (min-width: $screen-lg), (max-width: $screen-sm) {
        display: none;
      }
    }

    .video-mobile {
      @media (min-width: $screen-sm) {
        display: none;
      }
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }

  .light-wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    @media (max-width: $screen-lg) {
      display: none;
    }

    svg {
      min-width: calc(600 / 1440 * 100vw);
      width: auto;
      height: auto;

      @media (max-width: $screen-xxl) {
        min-width: 600px;
      }
    }
  }

  .grid-wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;

    @media (max-width: $screen-lg) {
      display: none;
    }

    img {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }

  .tabs-wrap {
    position: relative;
    z-index: 10;
    width: 100%;
    height: max-content;

    @media (max-width: $screen-lg) {
      margin: 0;
      padding: 0;
    }

    .nav-tabs-wrap {

      @media (max-width: $screen-lg) {
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        padding: 0 0 40px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .nav-tabs {
      position: absolute;
      z-index: 2;
      top: calc(160 / 1440 * 100vw);
      left: calc(170 / 1440 * 100vw);
      display: flex;
      flex-direction: column;
      padding: 0;
      border: 0;
      border-radius: 0;

      @media (max-width: $screen-xxl) {
        top: 160px;
        left: 170px;
      }

      @media (max-width: $screen-lg) {
        position: relative;
        top: 0;
        left: 0;
        flex-direction: row;
        width: max-content;
        padding: 0 0 0 20px;
      }

      .nav-item {
        margin-bottom: calc(8 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          margin-bottom: 8px;
        }

        @media (max-width: $screen-lg) {
          margin: 0 20px 0 0;
        }

        @media (max-width: $screen-sm) {
          margin: 0 10px 0 0;
        }

        .nav-link {
          font-size: calc(72 / 1440 * 100vw);
          font-family: $headline-font;
          line-height: 1;
          letter-spacing: calc(4 / 1440 * 100vw);
          color: $white-color;
          text-transform: uppercase;
          border: 0;
          padding: 0;
          border-radius: 0;
          background-color: transparent;
          cursor: pointer;

          @media (max-width: $screen-xxl) {
            font-size: 72px;
            letter-spacing: 4px;
          }

          @media (max-width: $screen-xl) {
            font-size: 48px;
          }

          @media (max-width: $screen-sm) {
            font-size: 32px;
          }

          &.active {

            &.nav-link-1 {
              color: #9655FF;
            }

            &.nav-link-2 {
              color: #83D2FF;
            }

            &.nav-link-3 {
              color: #E9ABE1;
            }

            &.nav-link-4 {
              color: #B6E568;
            }

            &.nav-link-5 {
              color: #FF7E55;
            }
          }
        }
      }

      .nav-tabs-progress-wrap {
        position: absolute;
        display: none;
        width: 100%;
        left: 0;
        bottom: -20px;

        @media (max-width: $screen-lg) {
          display: flex;
          align-items: center;
        }

        .line {
          display: flex;
          width: 100%;

          img {
            width: 100%;
          }
        }

        .highlighter {
          position: absolute;
          left: -4%;
          top: -18px;
          z-index: 1;

          &.transition-none {
            transition: none
          }

          img, svg {

          }
        }
      }
    }

    .tab-content {
      height: 100%;
      width: 100%;
      z-index: 0;
      position: relative;
      top: 0;
      left: 0;

      @media (max-width: $screen-lg) {
        position: relative;
      }

      .tab-pane {
        width: 100%;

        &.active {

          @media (max-width: $screen-lg) {
            display: flex;
            flex-direction: column-reverse;
            padding: 0 20px;
          }

          @media (max-width: $screen-sm) {
            flex-direction: column;
          }
        }

        @media (min-width: $screen-lg) {
          height: 100%;
        }
      }

      .img-wrap {
        width: 100%;
        height: 100%;

        @media (max-width: $screen-lg) {
          display: flex;
          justify-content: center;
          margin-top: -550px;
          position: relative;
        }

        @media (max-width: $screen-sm) {
          margin-top: 10px;
        }

        &::before {

          @media (max-width: $screen-lg) {
            content: '';
            display: block;
            height: 100px;
            width: calc(100% + 60px);
            position: absolute;
            top: -50px;
            left: -30px;
            background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00));
          }
        }

        &::after {

          @media (max-width: $screen-sm) {
            content: '';
            display: block;
            height: 100px;
            width: calc(100% + 60px);
            position: absolute;
            bottom: -20px;
            left: -30px;
            background: linear-gradient(0, #000 0%, rgba(0, 0, 0, 0.00));
          }
        }

        img {
          width: 100%;
          height: 100%;

          @media (min-width: $screen-lg) {
            object-fit: cover;
            object-position: top;
          }

          @media (max-width: $screen-xxl) {
            min-height: 100vh;
          }

          @media (max-width: $screen-lg) {
            width: 180vw;
            min-height: 100%;
          }

          @media (max-width: $screen-sm) {
            width: 160vw;
          }
        }
      }

      .text-wrap {
        position: absolute;
        top: calc(160 / 1440 * 100vw);
        left: calc(950 / 1440 * 100vw);
        max-width: calc(430 / 1440 * 100vw);

        @media (max-width: $screen-xxl) {
          top: 160px;
          left: auto;
          right: 50px;
          max-width: 430px;
        }

        @media (max-width: $screen-xl) {
          max-width: 360px;
        }

        @media (max-width: $screen-lg) {
          max-width: 100%;
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
          margin: 10px 0 0;
        }

        @media (max-width: $screen-sm) {
          margin: -100px 0 0;
        }

        h2 {
          font-size: calc(88 / 1440 * 100vw);
          font-family: $headline-font;
          line-height: 1;
          color: $white-color;
          text-transform: uppercase;
          letter-spacing: calc(4 / 1440 * 100vw);
          margin-bottom: calc(4 / 1440 * 100vw);

          @media (max-width: $screen-xxl) {
            font-size: 88px;
            margin-bottom: 4px;
          }

          @media (max-width: $screen-xl) {
            font-size: 64px;
            margin-bottom: 8px;
          }

          @media (max-width: $screen-sm) {
            font-size: 48px;
            margin-bottom: 16px;
          }
        }

        > p {
          font-size: calc(16 / 1440 * 100vw);
          font-family: $main-font;
          font-weight: 400;
          line-height: 1.2;
          color: $white-color;

          @media (max-width: $screen-xxl) {
            font-size: 16px;
          }

          @media (max-width: $screen-lg) {
            color: #D3D3D3;
          }
        }

        .data-wrap {
          margin-top: calc(32 / 1440 * 100vw);
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          @media (max-width: $screen-xxl) {
            margin-top: 32px;
          }

          @media (max-width: $screen-lg) {
            margin-top: 56px;
          }

          @media (max-width: $screen-sm) {
            margin-top: 24px;
          }

          .item:nth-child(2n-1) {
            width: 40%;

            @media (max-width: $screen-xl) {
              width: 100%;
            }
          }

          .item:nth-child(2n) {
            width: 60%;

            @media (max-width: $screen-xl) {
              width: 100%;
            }
          }
        }

        .data-wrap, .additional-wrap {

          .item {
            margin-bottom: calc(16 / 1440 * 100vw);

            @media (max-width: $screen-xxl) {
              margin-bottom: 16px;
            }

            @media (max-width: $screen-xl) {
              margin-bottom: 8px;
            }

            &:nth-child(2n) {
              @media (max-width: $screen-xl) {
                margin-bottom: 24px;
              }

              @media (max-width: $screen-lg) {
                margin-bottom: 0;
              }
            }
          }

          p {
            font-size: calc(16 / 1440 * 100vw);
            font-family: $main-font;
            font-weight: 400;
            line-height: 1.2;
            color: rgba(211, 211, 211, .6);

            @media (max-width: $screen-xxl) {
              font-size: 16px;
            }

            @media (max-width: $screen-xl) {
              display: flex;
              justify-content: flex-start;
            }

            @media (max-width: $screen-sm) {
              margin: 0;
            }

            span.symbol::after {
              content: ' ';

              @media (max-width: $screen-lg) {
                content: '';
                margin-right: 20px;
              }
            }

            span.value {
              color: $white-color;

              @media (max-width: $screen-xl) {
                margin-left: auto;
                text-align: right;
              }

              &::before {
                content: ' ';
              }
            }
          }
        }

        .data-wrap .item {

          &:nth-child(1) {
            @media (max-width: $screen-lg) {
              order: 1;
              margin-bottom: 8px;
            }
          }

          &:nth-child(2) {
            @media (max-width: $screen-lg) {
              order: 3;
              margin-bottom: 8px;
            }
          }

          &:nth-child(3) {
            @media (max-width: $screen-lg) {
              order: 2;
              margin-bottom: 56px;
            }
          }

          &:nth-child(4) {
            @media (max-width: $screen-lg) {
              order: 4;
              margin-bottom: 56px;
            }
          }

          &:nth-child(5) {
            @media (max-width: $screen-lg) {
              order: 6;
              margin-bottom: 56px;
            }
          }

          &:nth-child(6) {
            @media (max-width: $screen-lg) {
              order: 5;
              margin-bottom: 8px;
            }
          }
        }

        .additional-wrap .item:last-child {

          @media (max-width: $screen-lg) {
            margin-bottom: 0;
          }
        }

        .line-wrap {
          margin: calc(8 / 1440 * 100vw) 0 calc(24 / 1440 * 100vw);

          @media (max-width: $screen-xxl) {
            margin: 8px 0 24px;
          }

          @media (max-width: $screen-xl) {
            display: none;
          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .noise-wrap {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      display: none;

      @media (max-width: $screen-lg) {
        display: none;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.active-hero-1 {

  header .progress-wrap .highlighter {
    top: calc(110 / 1440 * 100vw) !important;

    @media (max-width: $screen-xxl) {
      top: 110px !important;
    }

    @media (max-width: $screen-xl) {
      top: 92px !important;
    }

    svg stop {
      stop-color: #9655FF;
    }
  }

  header .navbar .navbar-toggler {
    background-color: #9655FF;
  }

  header .navbar .navbar-collapse .navbar-socials a svg rect {
    fill: #9655FF;
  }

  header .navbar .navbar-collapse .navbar-socials a:hover svg rect {
    fill: #a26bfc;
  }

  .characters-hero-section .artifacts-wrap svg rect {
    fill: #9655FF;
  }

  .characters-hero-section .light-wrap svg stop {
    stop-color: #9655FF;
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap .highlighter {
    left: -4%;

    @media (max-width: $screen-sm) {
      left: -7%;
    }
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap svg stop {
    stop-color: #9655FF;
  }
}

.active-hero-2 {

  header .progress-wrap .highlighter {
    top: calc(195 / 1440 * 100vw) !important;

    @media (max-width: $screen-xxl) {
      top: 195px !important;
    }

    @media (max-width: $screen-xl) {
      top: 144px !important;
    }

    svg stop {
      stop-color: #83D2FF;
    }
  }

  header .navbar .navbar-toggler {
    background-color: #83D2FF;
  }

  header .navbar .navbar-collapse .navbar-socials a svg rect {
    fill: #83D2FF;
  }

  header .navbar .navbar-collapse .navbar-socials a:hover svg rect {
    fill: #a0dcff;
  }

  .characters-hero-section .artifacts-wrap svg rect {
    fill: #83D2FF;
  }

  .characters-hero-section .light-wrap svg stop {
    stop-color: #83D2FF;
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap .highlighter {
    left: 16%;

    @media (max-width: $screen-sm) {
      left: 13%;
    }
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap svg stop {
    stop-color: #83D2FF;
  }
}

.active-hero-3 {

  header .progress-wrap .highlighter {
    top: calc(265 / 1440 * 100vw) !important;

    @media (max-width: $screen-xxl) {
      top: 265px !important;
    }

    @media (max-width: $screen-xl) {
      top: 206px !important;
    }

    svg stop {
      stop-color: #E9ABE1;
    }
  }

  header .navbar .navbar-toggler {
    background-color: #E9ABE1;
  }

  header .navbar .navbar-collapse .navbar-socials a svg rect {
    fill: #E9ABE1;
  }

  header .navbar .navbar-collapse .navbar-socials a:hover svg rect {
    fill: #FFD6FA;
  }

  .characters-hero-section .artifacts-wrap svg rect {
    fill: #E9ABE1;
  }

  .characters-hero-section .light-wrap svg stop {
    stop-color: #E9ABE1;
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap .highlighter {
    left: 38%;

    @media (max-width: $screen-sm) {
      left: 33%;
    }
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap svg stop {
    stop-color: #E9ABE1;
  }
}

.active-hero-4 {

  header .progress-wrap .highlighter {
    top: calc(345 / 1440 * 100vw) !important;

    @media (max-width: $screen-xxl) {
      top: 345px !important;
    }

    @media (max-width: $screen-xl) {
      top: 258px !important;
    }

    svg stop {
      stop-color: #B6E568;
    }
  }

  header .navbar .navbar-toggler {
    background-color: #B6E568;
  }

  header .navbar .navbar-collapse .navbar-socials a svg rect {
    fill: #B6E568;
  }

  header .navbar .navbar-collapse .navbar-socials a:hover svg rect {
    fill: #d5ff8e;
  }

  .characters-hero-section .artifacts-wrap svg rect {
    fill: #B6E568;
  }

  .characters-hero-section .light-wrap svg stop {
    stop-color: #B6E568;
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap .highlighter {
    left: 54%;

    @media (max-width: $screen-sm) {
      left: 50%;
    }
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap svg stop {
    stop-color: #B6E568;
  }
}

.active-hero-5 {

  header .progress-wrap .highlighter {
    top: calc(425 / 1440 * 100vw) !important;

    @media (max-width: $screen-xxl) {
      top: 425px !important;
    }

    @media (max-width: $screen-xl) {
      top: 310px !important;
    }

    svg stop {
      stop-color: #FF7E55;
    }
  }

  header .navbar .navbar-toggler {
    background-color: #FF7E55;
  }

  header .navbar .navbar-collapse .navbar-socials a svg rect {
    fill: #FF7E55;
  }

  header .navbar .navbar-collapse .navbar-socials a:hover svg rect {
    fill: #ff9979;
  }

  .characters-hero-section .artifacts-wrap svg rect {
    fill: #FF7E55;
  }

  .characters-hero-section .light-wrap svg stop {
    stop-color: #FF7E55;
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap .highlighter {
    left: 75%;

    @media (max-width: $screen-sm) {
      left: 70%;
    }
  }

  .characters-hero-section .tabs-wrap .nav-tabs .nav-tabs-progress-wrap svg stop {
    stop-color: #FF7E55;
  }
}
